import React from 'react';
import './WalletSign.css';

function WalletSign() {
  return (
    <div className="wallet-sign">
      <h1>Wallet Sign</h1>
      <p>Sign transactions and messages with your wallet.</p>
      {/* Add your wallet sign content */}
    </div>
  );
}

export default WalletSign;