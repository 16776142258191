// startwithproject.js
import React from 'react';
import './startwithproject.css';

function StartWithProject() {
  return (
    <div className="start-with-project">
      <h2>Registration Requirements</h2>
      <p>To register for this DAPP, your wallet needs to meet one of the following requirements:</p>
      <ul>
        <li>Hold a specific token</li>
        <li>Be registered in our database</li>
      </ul>
      <p>Please make sure your wallet meets the requirements and try registering again.</p>
      <p>If you have any questions or need assistance, please contact our support team.</p>
    </div>
  );
}

export default StartWithProject;