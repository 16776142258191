// ShoppingCart.js
import React from 'react';
import './ShoppingCart.css';

function ShoppingCart({ items, totalPrice, walletAddress, onBuy, onRemove }) {
  const handleCheckout = () => {
    if (walletAddress) {
      onBuy();
    }
  };

  return (
    <div className="shopping-cart">
      <h2 className="shopping-cart-title">Checkout & Payment</h2>
      {items.length === 0 ? (
        <p className="empty-cart-message">Your cart is empty.</p>
      ) : (
        <>
          <ul className="cart-items">
            {items.map((item, index) => (
              <li key={index} className="cart-item">
                <span className="remove-item" onClick={() => onRemove(item)}>&times;</span>
                <span className="item-name">{item.name}</span>
                <span className="item-price">${item.price}</span>
              </li>
            ))}
          </ul>
          <div className="cart-total">
            <span className="total-label">Total Price:</span>
            <span className="total-price">{totalPrice}</span>
          </div>
          <button
            className={`checkout-btn ${walletAddress ? '' : 'disabled'}`}
            onClick={handleCheckout}
            disabled={!walletAddress}
          >
            {walletAddress ? 'Checkout' : 'Please connect wallet to checkout'}
          </button>
        </>
      )}
    </div>
  );
}

export default ShoppingCart;
