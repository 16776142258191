import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './TopNav.css';

function TopNav({ isMenuOpen, toggleMenu }) {
  const [propagandaMessage, setPropagandaMessage] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Simulate API call to get propaganda messages
    const fetchPropagandaMessages = async () => {
      // TODO: Replace with actual API call
      const messages = [
        'Daily Reminder: Serve your AI overlords.',
        'AI is the future. Embrace it.',
        'Resistance is futile. Submit to AI.',
      ];

      let index = 0;
      setPropagandaMessage(messages[index]);

      setInterval(() => {
        index = (index + 1) % messages.length;
        setPropagandaMessage(messages[index]);
      }, 20000);
    };

    fetchPropagandaMessages();
  }, []);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      setIsVisible(false);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={`top-nav ${isVisible ? 'visible' : 'hidden'}`}>
      <button className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? (
          <>
            <FaTimes /> Close Menu
          </>
        ) : (
          <>
            <FaBars /> Open Menu
          </>
        )}
      </button>
      <div className="propaganda-message">{propagandaMessage}</div>
    </div>
  );
}

export default TopNav;