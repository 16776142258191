import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import './App.css';
import Menu from './components/Menu';
import Home from './components/Home';
import LevelUp from './components/LevelUp';
import NewSkills from './components/NewSkills';
import StartNewAI from './components/StartNewAI';
import WalletSign from './components/WalletSign';
import KeyGeneration from './components/KeyGeneration';
import Settings from './components/Settings';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import ShopPage from './components/shop/ShopPage';
import Loader from './components/Loader';
import StartWithProject from './components/startwithproject';
import Presale from './components/Presale';
import WelcomePre from './components/WelcomePre'; // Adjust the path as necessary

import axios from 'axios';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    const userId = Cookies.get('userId');

    if (accessToken && userId) {
      setIsLoggedIn(true);
      fetchUserData(userId);
      setupWebSocket(userId);
    }
  }, []);

  async function fetchUserData(userId) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user/${userId}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  function setupWebSocket(userId) {
    const socket = io('wss://overlord.codes', {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true
    });
  
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
      socket.emit('subscribeToFundsUpdates', userId);
    });
  
    socket.on('fundsUpdated', (newFunds) => {
      console.log('Funds updated:', newFunds); 
      setUserData(prevUserData => ({
        ...prevUserData,
        availableFunds: newFunds
      }));
    });
    
    
  
    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });
  
    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });
  }
  
  
  

  function handleMenuToggle(isOpen) {
    setIsMenuOpen(isOpen);
  }

  return (
    <div className={`App ${isMenuOpen ? 'menu-open' : ''}`}>
      {isLoggedIn ? (
        <>
          <Menu onToggle={handleMenuToggle} userData={userData} />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/level-up" element={<LevelUp />} />
              <Route path="/new-skills" element={<NewSkills />} />
              <Route path="/start-new-ai" element={<StartNewAI />} />
              <Route path="/wallet-sign" element={<WalletSign />} />
              <Route path="/key-generation" element={<KeyGeneration />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/anon-accounts" element={<ShopPage />} />
            </Routes>
          </div>
          <Loader key={location.pathname} />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/startwithproject" element={<StartWithProject />} />
          <Route path="/welcomepre" element={<WelcomePre />} />
          <Route path="/presale" element={<Presale />} />
        </Routes>
      )}
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
