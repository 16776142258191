import React from 'react';
import './NotificationPopup.css'; // Make sure to create corresponding CSS for styling

function NotificationPopup({ message, onClose }) {
  console.log("Rendering NotificationPopup");  // Debugging line
  return (
    <div className="notification-popup">
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default NotificationPopup;
