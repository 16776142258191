import React from 'react';
import { Link } from 'react-router-dom';
import './WelcomePre.css'; // Make sure to create and link this CSS file

function WelcomePre() {
    return (
        <div className="welcome-pre-page">
            <Link to="/presale" className="option-container clickable">
                <h2>Through Our DApp</h2>
                <p>
                    Prefer a guided approach? Use our DApp to interact with the blockchain.
                    This method provides a user-friendly interface for managing transactions,
                    tracking progress, and receiving updates. Perfect for those who appreciate
                    the convenience and features of a dedicated DApp.
                </p>
            </Link>
            <div className="option-container clickable" onClick={() => console.log('Placeholder for future functionality')}>
                <h2>Fully Decentralized Method</h2>
                <p>
                    This method allows you to interact directly with the blockchain,
                    without any intermediary DApp interfaces. Simply send ETH directly
                    to our contract, and your transaction is processed entirely on-chain.
                    Ideal for those who prefer complete decentralization and minimal
                    third-party interaction.
                </p>
            </div>
            <div className="video-section">
                <h2>Explainer Videos</h2>
                <div className="video-placeholder">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/your_video_id"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>
</div>

            </div>
        </div>
    );
}

export default WelcomePre;
