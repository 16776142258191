// PresaleMenu.js
import React from 'react';
import { ethers } from 'ethers';
import './PresaleMenu.css';
import disconnectIcon from './exit.png';  // Ensure you adjust the path to your icon

function PresaleMenu({ walletAddress, setWalletAddress }) {

  async function handleConnectWallet() {
    try {
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
      } else {
        console.log('No wallet extension found');
      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  }

  function handleDisconnectWallet() {
    setWalletAddress('');
    window.ethereum?.removeAllListeners();
    console.log('Wallet disconnected');
  }
  return (
    <div className="presale-menu">
            <div className="scrolling-text">
        <span className="scroll-content">You are on: dapp.btfo.ai/presale – Verify this is the correct URL</span>
      </div>
      <div className="menu-header">
        {/* Any header content */}
      </div>
      <div className="menu-flex">
        <div className="wallet-section">
          {walletAddress ? (
            <>
              <img src={disconnectIcon} alt="Disconnect" className="disconnect-icon" onClick={handleDisconnectWallet} />
              <span className="wallet-address">{walletAddress}</span>
            </>
          ) : (
            <button className="connect-wallet-btn" onClick={handleConnectWallet}>Connect Wallet</button>
          )}
        </div>
        <div className="links-section">
          <a href="/docs" target="_blank" rel="noopener noreferrer">Docs</a>
          <a href="/telegram" target="_blank" rel="noopener noreferrer">Telegram</a>
        </div>
      </div>
    </div>
  );
  
}

export default PresaleMenu;
