// ProductCard.js
import React from 'react';

function ProductCard({ name, description, price, image, onLearnMore, onAddToCart }) {
  return (
    <div className="presale-product-card">
      <div className="presale-product-image">
        <img src={image} alt={name} />
      </div>
      <div className="presale-product-details">
        <h3 className="presale-product-name">{name}</h3>
        <p className="presale-product-description">{description}</p>
        <p className="presale-product-price">{price}</p>
      </div>
      <div className="presale-product-buttons">
        <button className="presale-btn" onClick={onAddToCart}>
          Pre-Order Now
        </button>
        <button className="presale-btn" onClick={onLearnMore}>
          Learn More
        </button>
      </div>
    </div>
  );
}

export default ProductCard;