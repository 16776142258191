import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import PresaleMenu from './PresaleMenu';  // Import the PresaleMenu component
import ProductCard from './presale/ProductCard';  // Import the ProductCard component
import ShoppingCart from './presale/ShoppingCart';  // Import the ShoppingCart component
import ProductPopup from './presale//ProductPopup';  // Import the ProductPopup component
import NotificationPopup from './presale/NotificationPopup'; // Import the NotificationPopup component
import './Presale.css';

function Presale() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [walletAddress, setWalletAddress] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [ethPrice, setEthPrice] = useState(null);

  useEffect(() => {
    fetchEthPrice().then(price => setEthPrice(price));
  }, []);// Empty dependency array means this effect runs once after the initial render


  const fetchEthPrice = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      const data = await response.json();
      return data.ethereum.usd;  // Returns the price of one ETH in USD
    } catch (error) {
      console.error('Failed to fetch ETH price:', error);
      return null;  // Handle error appropriately
    }
  };
  

    
  const handleAddToCart = (product) => {
    if (cartItems.length >= 1) {
      // If any item exists in the cart, show popup and prevent adding new items
      setPopupMessage("STRICTLY LIMITED, MAX ONE PRE ORDER PER WALLET");
      setShowPopup(true);
    } else {
      // If the cart is empty, add the selected product
      setCartItems([product]);
    }
  };
  
// Inside Presale.js
const handleRemoveFromCart = (productToRemove) => {
    setCartItems(currentItems => currentItems.filter(item => item.name !== productToRemove.name));
  };
  

  const handleClosePopup = () => {
    setSelectedProduct(null);
    setShowPopup(false);
  };

  const products = [
    {
      name: 'AI Nomad PRO',
      description: 'The ultimate companion for the resilient explorer',
      details: 'This portable device is designed for the harshest environments with a focus on robustness and self-sufficiency. Features include an emergency power bank, Level 2 EMP protection compliant with MIL-STD-461G, and advanced electrical safety through GFCI (Ground Fault Circuit Interrupter) for superior electrical shock protection. It supports decentralized data storage, operates fully offline, and utilizes NVIDIA Jetson Orin NX 16GB for advanced AI tasks. Additional features include M.2 AI accelerators, high-capacity SSD storage, dedicated microcontrollers for enhanced hotword detection, and additional AI accelerators for optimized voice interaction.',
      price: 3000,
      image: 'path/to/nomad-pro-image.jpg',
    },
    {
      name: 'AI Burg PRO',
      description: 'Your personal fortress of solitude',
      details: 'Equipped with NVIDIA Tesla A100 Ampere 40 GB GPUs for high-demand AI processing, this device serves as a comprehensive AI training and smart home management hub. Features large-scale storage solutions, sophisticated home automation capabilities, and an energy management system with PE (Protective Earth) fault protection to ensure operational safety and efficiency. It supports predominantly offline operation to enhance data privacy and allows users to generate passive income by renting out its processing power for external AI training tasks.',
      price: 25000,
      image: 'path/to/burg-pro-image.jpg',
    },
    {
      name: 'AI Agents - Cloud Only',
      description: 'Cloud-based AI assistant for digital nomads',
      details: 'Provides state-of-the-art cloud-based AI services with high accessibility and performance. Tailored for users who demand flexibility, this service offers comprehensive virtual assistance, automated task management, and user-specific customization. It utilizes cutting-edge AI technology for real-time, intuitive user interactions, suitable for those seeking a powerful, efficient AI assistant without the need for physical hardware.',
      price: 500,
      image: 'path/to/ai-agents-image.jpg',
    }
];

  
  

  const handleLearnMore = (product) => {
    setSelectedProduct(product);
  };
  // Calculate ETH prices for each product dynamically
  const productWithEthPrices = products.map(product => ({
    ...product,
    ethPrice: ethPrice ? (product.price / ethPrice).toFixed(4) : "Loading..."
  }));

  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  const handlePurchase = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signature = await signer.signMessage("Test");
        console.log('Signature:', signature);
      } catch (error) {
        console.error('Error signing message:', error);
      }
    } else {
      console.log('No Ethereum provider is available');
    }
  };

  return (
    <div className="presale-shop-page">
      <PresaleMenu 
        walletAddress={walletAddress} 
        setWalletAddress={setWalletAddress}
      />
      <div className="presale-container">
        <h1 className="presale-title">Liquidity bootstrapping - PRE ORDER </h1>
        <div className="presale-product-grid">
        {productWithEthPrices.map((product, index) => (
          <ProductCard
            key={index}
            name={product.name}
            description={product.description}
            price={`$${product.price} USD / ${product.ethPrice} ETH`}
            image={product.image}
            onLearnMore={() => handleLearnMore(product)}
            onAddToCart={() => handleAddToCart(product)}
          />
        ))}
      </div>
        <ShoppingCart 
  items={cartItems}
  totalPrice={`$${totalPrice}`}
  walletAddress={walletAddress}
  onBuy={handlePurchase}
  onRemove={handleRemoveFromCart}  // Passing the remove function as a prop
/>

      </div>
      {selectedProduct && (
        <ProductPopup product={selectedProduct} onClose={handleClosePopup} />
      )}
      {showPopup && (
        <NotificationPopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default Presale;
