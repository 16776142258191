import React, { useEffect, useRef, useState } from 'react';
import './Loader.css';

const Loader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const canvasRef = useRef(null);

  useEffect(() => {
    const duration = Math.random() * 1800 + 200; // Random duration between 0.2 and 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const w = (canvas.width = window.innerWidth);
    const h = (canvas.height = window.innerHeight);

    const cols = Math.floor(w / 20) + 1;
    const ypos = Array(cols).fill(0);

    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, w, h);

    const matrix = () => {
      ctx.fillStyle = '#0001';
      ctx.fillRect(0, 0, w, h);

      ctx.fillStyle = '#ff0000';
      ctx.font = '15pt monospace';

      ypos.forEach((y, ind) => {
        const text = String.fromCharCode(Math.random() * 128);
        const x = ind * 20;
        ctx.fillText(text, x, y);
        if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
        else ypos[ind] = y + 20;
      });
    };

    const intervalId = setInterval(matrix, 50);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, []);

  if (!isLoading) {
    return null;
  }

  return (
    <div className="loader">
      <canvas ref={canvasRef} className="matrix-canvas" />
      <div className="loader-content">
        <h1 className="loading-text">Loading...</h1>
      </div>
    </div>
  );
};

export default Loader;