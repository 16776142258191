import React from 'react';
import './Settings.css';

function Settings() {
  return (
    <div className="settings">
      <h1>Settings</h1>
      <p>Configure your account settings and preferences.</p>
      {/* Add your settings content */}
    </div>
  );
}

export default Settings;