import React from 'react';
import './StartNewAI.css';

function StartNewAI() {
  return (
    <div className="start-new-ai">
      <h1>Start New AI</h1>
      <p>Create and deploy a new AI instance.</p>
      {/* Add your start new AI content */}
    </div>
  );
}

export default StartNewAI;