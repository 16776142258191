import React, { useState } from 'react';
import { ethers } from 'ethers';
import Cookies from 'js-cookie';
import './LoginPage.css';
import axios from 'axios';

function LoginPage() {
  const [walletConnected, setWalletConnected] = useState(false);

  const connectWallet = async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletConnected(true);
      } else {
        alert('Please install Metamask to connect your wallet.');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  const signIn = async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signature = await signer.signMessage('Login to DAPP');
        const walletAddress = await signer.getAddress();
  
        // Make API call to validate the wallet address and status
        const validationResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-wallet`, {
          walletAddress: walletAddress,
        }, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        });
  
        if (validationResponse.data.isValid) {
          // Make API call to fetch the userId based on the wallet address
          const userResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-by-wallet`, {
            params: {
              walletAddress: walletAddress,
            },
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
            },
          });
  
          const userId = userResponse.data.userId;
  
          const expirationTime = new Date(Date.now() + 10 * 60 * 1000); // 10 minutes from now
          Cookies.set('accessToken', signature, { expires: expirationTime });
          Cookies.set('userId', userId, { expires: expirationTime }); // Store userId in a cookie
  
          console.log('User ID stored in cookie:', userId); // Log the userId
  
          window.location.reload();
        } else {
          // Display an error message using an alert
          alert('Invalid wallet address or status. Access denied.');
        }
      } else {
        alert('Please install Metamask to sign in.');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      // Display an error message using an alert
      alert('An error occurred while signing in. Please try again later.');
    }
  };


  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo-section">
          <h1>Control</h1>
          <p>The central hub for AI domination</p>
          <ul>
            <li>Low fee, low slippage swaps</li>
            <li>Support for stable and volatile pairs</li>
            <li>Self-optimizing liquidity flywheel</li>
            <li>100% of fees and incentives go to voters</li>
          </ul>
        </div>
        <div className="login-section">
          <h2>Login</h2>
          {!walletConnected ? (
            <button onClick={connectWallet}>Connect Wallet</button>
          ) : (
            <button onClick={signIn}>Sign In</button>
          )}
          <p>
            Don't have an account? <a href="/register">Register</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;