import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './Home.css';

function Home() {
  const [displayInfo, setDisplayInfo] = useState('');

  useEffect(() => {
    $("button").hide();
    $("button").each(function(i) {
      var name = $(this).attr('value');
      $(this).delay(i*100).slideDown(60);
      $(this).html(name);
    });

    $("button").click(function(){
      var name = $(this).attr('value');
      if (name === "") {
        // Handle empty button value
      } else {
        // Simulate fetching information based on the button value
        let info = '';
        if (name === '1') {
          info = 'Info 1';
        } else if (name === '2') {
          info = 'Info 2';
        } else if (name === '3') {
          info = 'Info 3';
        }
        setDisplayInfo(info);
      }
    });
  }, []);

  return (
    <div className="screen">
      <section className="buttonGroup">
        <h1>Status</h1>
        <div className="visual1" />
        <div className="visual2">
          <span className="display-info">{displayInfo}</span>
        </div>
        <section className="setButtons">
          <button value="1"></button>
          <button value="2"></button>
          <button value="3"></button>
          <button value="4"></button>
          <button value="x"></button>
          <button value="x"></button>
          <button value="x"></button>
          <button value="x"></button>
          <button value="x"></button>
          <button value="x"></button>
          </section>
        <section>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sit amet est nisl. Mauris euismod vel quam eu tincidunt. Sed dapibus justo id dapibus tempor. Pellentesque a libero sed risus congue ultrices. Quisque at congue massa. Duis feugiat ligula non nisi semper tempus. Duis blandit lacus sed diam blandit, id faucibus nunc viverra. Morbi consequat consequat facilisis. Sed in felis vitae nibh venenatis finibus sed et dolor. Sed vulputate molestie est ac tristique. Vestibulum et aliquam velit, in convallis nisl. Vivamus pellentesque venenatis urna eget suscipit.
          </p>
          <hr />
          <p>
            Sed rhoncus a leo congue sollicitudin. Fusce risus tortor, rutrum id egestas at, elementum in dui. Fusce bibendum purus eu turpis vestibulum, eget aliquet ante pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce egestas, mauris eu vehicula placerat, lacus mi hendrerit lacus, ac rhoncus arcu purus eget odio. Aliquam commodo mattis lorem vitae porttitor.
          </p>
        </section>
      </section>
    </div>
  );
}

export default Home;