// RegisterPage.js
import React, { useState } from 'react';
import { ethers } from 'ethers';
import Cookies from 'js-cookie';
import './RegisterPage.css';
import AuthKeyAnimation from './AuthKeyAnimation';
import axios from 'axios';

function RegisterPage() {
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [authKey, setAuthKey] = useState('');
  const [showAnimation, setShowAnimation] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);


  const connectWallet = async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletConnected(true);
        setProgress(50);
      } else {
        alert('Please install Metamask to connect your wallet.');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  const register = async () => {
    if (typeof window.ethereum === 'undefined') {
      alert('Please install MetaMask to register.');
      return; // Early exit if MetaMask is not installed
    }
  
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage('Register for DAPP');
      const walletAddress = await signer.getAddress();
  
      const response = await axios.post('https://overlord.codes/api/verify-signature', {
        signedMessage: signature,
        walletAddress: walletAddress,
      }, {
        headers: {
          'x-api-key': 'testkey123',
        },
      });
  
      if (response.data.message === 'Signature verified and wallet is eligible') {
        setShowAnimation(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        const { authKey } = response.data;  // Retrieve the key from the response
        const expirationTime = new Date(Date.now() + 10 * 60 * 1000);
        Cookies.set('accessToken', authKey, { expires: expirationTime });
        setAuthKey(authKey);
        setProgress(100);
      } else {
        alert('Verification failed: ' + response.data.message);
        window.location.href = '/startwithproject';
      }
    } catch (error) {
      console.error('Error registering:', error);
      alert('An error occurred during registration. Please try again.');
      window.location.href = '/startwithproject';
    } finally {
      setLoading(false);
    }
  };
  

  const handleAnimationComplete = () => {
    setShowAnimation(false);
    setRegistrationComplete(true);
  };

  const handleContinue = () => {
    window.location.href = '/';
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(authKey).then(() => {
      setCopiedToClipboard(true);
      setTimeout(() => {
        setCopiedToClipboard(false);
      }, 2000);
    });
  };

  return (
    <div className="register-page">
      <div className={`register-container ${registrationComplete ? 'registration-complete' : ''}`}>
        {registrationComplete ? (
          <>
            <h2>Registration Successful!</h2>
            <p>Your authentication key:</p>
            <p className="auth-key">{authKey}</p>
            <button className="copy-button" onClick={copyToClipboard}>
              {copiedToClipboard ? 'Copied!' : 'Click to Copy'}
            </button>
            <button onClick={handleContinue}>Continue</button>
          </>
        ) : (
          <>
            <div className="register-content">
              <div className="register-gif">
                <img src="register.gif" alt="Register" />
              </div>
              <div className="register-text">
                <h1>Register Now</h1>
                <p>Connect your wallet and sign a message to register.</p>
                {!walletConnected ? (
                  <button onClick={connectWallet} disabled={loading}>
                    {loading ? 'Connecting...' : 'Connect Wallet'}
                  </button>
                ) : (
                  <button onClick={register} disabled={loading}>
                    {loading ? 'Registering...' : 'Sign Message'}
                  </button>
                )}
              </div>
            </div>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="register-video">
              <iframe
                src="https://www.youtube.com/embed/VIDEO_ID"
                title="YouTube Video"
                allowFullScreen
              ></iframe>
            </div>
          </>
        )}
      </div>
      {showAnimation && <AuthKeyAnimation onComplete={handleAnimationComplete} />}
    </div>
  );
}

export default RegisterPage;