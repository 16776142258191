// AuthKeyAnimation.js
import React, { useEffect, useRef, useState } from 'react';
import './AuthKeyAnimation.css';

const AuthKeyAnimation = ({ onComplete }) => {
  const canvasRef = useRef(null);
  const [loadingStep, setLoadingStep] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const w = (canvas.width = window.innerWidth);
    const h = (canvas.height = window.innerHeight);
    const cols = Math.floor(w / 20) + 1;
    const ypos = Array(cols).fill(0);
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, w, h);

    const matrix = () => {
      ctx.fillStyle = '#0001';
      ctx.fillRect(0, 0, w, h);
      ctx.fillStyle = '#ff0000';
      ctx.font = '15pt monospace';
      ypos.forEach((y, ind) => {
        const text = String.fromCharCode(Math.random() * 128);
        const x = ind * 20;
        ctx.fillText(text, x, y);
        if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
        else ypos[ind] = y + 20;
      });
    };

    const intervalId = setInterval(matrix, 50);

    const timer = setTimeout(() => {
      clearInterval(intervalId);
      onComplete();
    }, 4000);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [onComplete]);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingStep((prevStep) => (prevStep + 1) % 3);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const loadingText = [
    'Connecting to the Blockchain...',
    'Validating your wallet address...',
    'Checking...',
  ];

  return (
    <div className="auth-key-animation">
      <canvas ref={canvasRef} className="matrix-canvas" />
      <div className="animation-content">
        <h2>{loadingText[loadingStep]}</h2>
      </div>
    </div>
  );
};

export default AuthKeyAnimation;