import React from 'react';
import './LevelUp.css';

function LevelUp() {
  return (
    <div className="level-up">
      <h1>Level Up</h1>
      <p>Here you can level up your AI and unlock new capabilities.</p>
      {/* Add your level up content */}
    </div>
  );
}

export default LevelUp;