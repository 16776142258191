import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ShopPage.css';

const ShopPage = () => {
  const [visibleProducts, setVisibleProducts] = useState(3);

  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 3);
  };

  const [products] = useState([
        {
          id: 1,
          name: 'Basic Anon Account',
          description: 'Start your anonymous journey with this basic account.',
          price: 49.99,
          imageUrl: 'https://example.com/basic-anon-account.jpg',
        },
        {
          id: 2,
          name: 'Pro Anon Account',
          description: 'Enhance your anonymity with advanced features and increased security.',
          price: 99.99,
          imageUrl: 'https://example.com/pro-anon-account.jpg',
        },
        {
          id: 3,
          name: 'Elite Anon Account',
          description: 'Experience the ultimate level of anonymity and privacy.',
          price: 149.99,
          imageUrl: 'https://www.softwaretestinghelp.com/wp-content/qa/uploads/2019/04/Introduction2.jpg',
        },
        {
          id: 4,
          name: 'Anon VPN Package',
          description: 'Secure your online activities with our robust VPN service.',
          price: 79.99,
          imageUrl: 'https://example.com/anon-vpn-package.jpg',
        },
        {
          id: 5,
          name: 'Anon Email Package',
          description: 'Communicate anonymously with our secure email service.',
          price: 59.99,
          imageUrl: 'https://example.com/anon-email-package.jpg',
        },
        {
          id: 6,
          name: 'Anon Hosting Package',
          description: 'Host your websites and projects anonymously with our reliable hosting service.',
          price: 89.99,
          imageUrl: 'https://example.com/anon-hosting-package.jpg',
        },
        {
            id: 7,
            name: 'Anon Hosting Package',
            description: 'Host your websites and projects anonymously with our reliable hosting service.',
            price: 89.99,
            imageUrl: 'https://example.com/anon-hosting-package.jpg',
          },
          {
            id: 8,
            name: 'Anon Hosting Package',
            description: 'Host your websites and projects anonymously with our reliable hosting service.',
            price: 89.99,
            imageUrl: 'https://example.com/anon-hosting-package.jpg',
          },
          {
            id: 9,
            name: 'Anon Hosting Package',
            description: 'Host your websites and projects anonymously with our reliable hosting service.',
            price: 89.99,
            imageUrl: 'https://example.com/anon-hosting-package.jpg',
          },
    ]);

    const displayedProducts = products.slice(0, visibleProducts);

  return (
    <div className="shop-page">
      <h1>Anon Accounts Shop</h1>
      <div className="product-grid">
        {displayedProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      {visibleProducts < products.length && (
        <div className="load-more-btn">
          <button className="btn-load" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

  
  const ProductCard = ({ product }) => {
    return (
      <div className="product-card">
        <div className="product-image">
          <img src={product.imageUrl} alt={product.name} />
        </div>
        <div className="product-details">
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <p>Price: ${product.price.toFixed(2)}</p>
        </div>
        <div className="product-buttons">
          <Link to={`/anon-accounts/${product.id}`} className="btn details-btn">
            Details
          </Link>
          <Link to={`/anon-accounts/buy/${product.id}`} className="btn buy-btn">
            Buy
          </Link>
        </div>
      </div>
    );
  };
  
  export default ShopPage;
  