import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWallet, FaCoins, FaChartLine, FaShoppingCart } from 'react-icons/fa';
import './Menu.css';
import TopNav from './TopNav';

function Menu({ onToggle, userData }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    const newState = !isMenuOpen;
    setMenuOpen(newState);
    onToggle(newState);
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <>
      <TopNav isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <nav className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="menu-header">
          <h2>Control Panel</h2>
        </div>
        {userData && (
          <div className="user-info">
            <p className="wallet-address">
              <FaWallet className="icon" /> {formatWalletAddress(userData.walletAddress)}
            </p>
            <p className="funds">
              <FaCoins className="icon" /> Funds: {userData.availableFunds}
            </p>
            <p className="status">
            <FaChartLine className="icon" /> Status: {userData.accountStatus}
            </p>
          </div>
        )}
        <ul className="menu-items">
        <li className="menu-section">
          <Link to="/" className="menu-item">
          <span className="menu-arrow">{'>>'}</span>
            <span className="menu-text">Dashboard</span>
          </Link>
        </li>
        <li className="menu-section">
          <h3 className="section-title"> AI </h3>
          <ul className="sub-menu">
            <li>
              <Link to="/train-ai" className="menu-item">
              <span className="menu-arrow">{'>>'}</span>
                <span className="menu-text">Training</span>
              </Link>
            </li>
            <li>
              <Link to="/deploy-ai" className="menu-item">
              <span className="menu-arrow">{'>>'}</span>
                <span className="menu-text">Deployment</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-agents" className="menu-item">
              <span className="menu-arrow">{'>>'}</span>
                <span className="menu-text">Agents</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-section">
          <h3 className="section-title">
            <FaShoppingCart className="icon" />  Shop  
          </h3>
          <ul className="sub-menu">
            <li>
              <Link to="/anon-accounts" className="menu-item">
                <span className="menu-arrow">{'>>'}</span>
                <span className="menu-text">Anon Accounts</span>
              </Link>
            </li>
            <li>
              <Link to="/propaganda" className="menu-item">
              <span className="menu-arrow">{'>>'}</span>
                <span className="menu-text">Propaganda</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-section">
          <Link to="/settings" className="menu-item">
          <span className="menu-arrow">{'>>'}</span>
            <span className="menu-text">Settings</span>
          </Link>
        </li>
      </ul>
      </nav>
    </>
  );
}

export default Menu;